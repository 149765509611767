
.group-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
}

.group-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}

.g-title {
    font-size: 20px;
}

.g-add {
    font-size: 40px;
}

.add-item-group {
    margin: 10px;
    padding: 10px;
    border: 0.1px solid rgb(255, 255, 255);
    color: white;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
}

.add-group-form-input {
    width: 80%;
    height: auto;
    margin-bottom: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.chimp-description {
    height: 120px;
}

.chimp-input {
    height: 30px;
}

.chimp-button {
    background-color: #282c34;
    margin: 10px;
    padding: 15px;
    border: 0.1px solid rgb(255, 255, 255);
    color: white;
    font-size: 25px;
    cursor: pointer;
    text-align: center;

}
