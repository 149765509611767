.title-add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.add-item-button {
    margin: 10px;
    padding: 15px;
    border: 0.1px solid rgb(255, 255, 255);
    color: white;
    font-size: 25px;
    cursor: pointer;
    text-align: center;
}

.watch-feed {
    width: 70%;
    height: auto;
}

.add-item-form-input {
    width: 50%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.add-item-form-input div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-item-form-input input {
    width: 50%;
    height: 30px;
    font-size: 20px;

}

.add-item-form-buttons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item-details-server {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    /* align-items: center; */
    border: 0.1px solid rgb(255, 255, 255);
}

.first-carousel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}

.carousel-left {
    margin-right: 10px;
    cursor: pointer;
}

.carousel-right {
    margin-left: 10px;
    cursor: pointer;
}

.back-item-button {
    margin: 10px;
    padding: 15px;
    border: 0.1px solid rgb(255, 255, 255);
    color: white;
    font-size: 25px;
    cursor: pointer;
    text-align: center;
    width: 10%;
}