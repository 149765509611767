li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
}

.group-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
}

.group-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

.group-sub-item {
    margin: 10px;
    margin-left: 20px;
    font-size: 18px;
    display: none;
}

.group-item-name {
    cursor: pointer;
}

.group-sub-item-name {
    display: flex;
    flex-direction: column;
}

.group-sub-item-name span {
    margin-top: 10px;
}



