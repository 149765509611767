p {
    margin: 0px;
}

.items {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    width: 95%;
    border: 0.1px solid rgb(255, 255, 255);
    box-shadow: 0px 10px 0px rgb(255, 255, 255);
    margin-bottom: 10px;
}

.item-image {
    width: 50%
}

.item-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-heading {
    font-size: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.item-description,
.current-price,
.watch-for,
.item-description-edit,
.current-price-edit,
.watch-for-edit {
    font-size: 18px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.watch-for {
    display: flex;
    flex-direction: row;
}

.action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
}

.button {
    width: 33%;
    margin-bottom: 10px;
    padding: 5px;
    border: 0.1px solid rgb(255, 255, 255);
    color: white;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
}

.item-description-edit textarea {
    width: 80%;
    height: 80px;
}

.current-price-edit,
.watch-for-edit {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.current-price-edit input,
.watch-for-edit input {
    width: 30%;
    height: 20px;
    font-size: 20px;
}

.no-group-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}