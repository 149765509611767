.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    background-color: #282c34;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 100;
    font-size: 50px;
    top: 0;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
}

.header {
    margin-left: 10px;
}

.user-options {
    margin-right: 20px;
    font-size: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-options img {
    margin-left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

}

.home-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
}

.content {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 100px;
}

.group {
    width: 20%;
    font-size: 30px;
}

.feed {
    width: 70%;
    height: auto;
    font-size: 30px;
    margin-left: 20px
}